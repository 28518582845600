<template>
  <Breadcrumb
    v-if="detailTitle"
    :detailTitle="detailTitle.toUpperCase()"
    :objectName="detailTitle"
    :isDropdownList="true"
  />
  <Breadcrumb v-else detailTitle="&nbsp;" :isDropdownList="true" />
  <div class="row">
    <div class="col">
      <div class="card customobject-detail-container">
        <div class="row">
          <div class="col-xl-3 col-xxl-3">
            <div class="customobject-detail-menu">
              <ul class="list-unstyled customobject-detail-status-filter">
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'detail' ? 'active' : ''
                    "
                    :to="{
                      name: 'CustomObjectDetail',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-info-circle"></i
                    >{{
                      $t(
                        "CustomObjects.Detail",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'fields' ? 'active' : ''
                    "
                    :to="{
                      name: 'CustomObjectFields',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-card-list"></i
                    >{{
                      $t(
                        "CustomObjects.Fields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'pagelayouts'
                        ? 'active'
                        : ''
                    "
                    :to="{
                      name: 'CustomObjectPageLayouts',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-window"></i
                    >{{
                      $t(
                        "CustomObjects.PageLayouts",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'viewfilters'
                        ? 'active'
                        : ''
                    "
                    :to="{
                      name: 'CustomObjectViewFilters',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-funnel"></i
                    >{{
                      $t(
                        "CustomObjects.ViewFilters",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'objectrelations'
                        ? 'active'
                        : ''
                    "
                    :to="{
                      name: 'CustomObjectObjectRelations',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-bounding-box"></i
                    >{{
                      $t(
                        "CustomObjects.ObjectRelations",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'validationrules'
                        ? 'active'
                        : ''
                    "
                    :to="{
                      name: 'CustomObjectValidationRules',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-tools"></i
                    >{{
                      $t(
                        "CustomObjects.ValidationRules",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'businessRules'
                        ? 'active'
                        : ''
                    "
                    :to="{
                      name: 'CustomObjectBusinessRules',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-lightning"></i
                    >{{
                      $t(
                        "CustomObjects.BusinessRules",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'setscripts' ? 'active' : ''
                    "
                    :to="{
                      name: 'CustomObjectSetScripts',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-code-slash"></i
                    >{{
                      $t(
                        "CustomObjects.Scripts",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'buttons' ? 'active' : ''
                    "
                    :to="{
                      name: 'CustomObjectButtons',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-app"></i
                    >{{
                      $t(
                        "CustomObjects.Buttons",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'formTemplates'
                        ? 'active'
                        : ''
                    "
                    :to="{
                      name: 'CustomObjectFormTemplates',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                    ><i class="bi bi-file-text"></i
                    >{{
                      $t(
                        "CustomObjects.FormTemplates",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'kanban' ? 'active' : ''
                    "
                    :to="{
                      name: 'CustomObjectKanban',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-kanban"></i
                    >{{
                      $t(
                        "CustomObjects.KanbanSettings",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'calendars' ? 'active' : ''
                    "
                    :to="{
                      name: 'CustomObjectCalendars',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-calendar-week"></i
                    >{{
                      $t(
                        "CustomObjects.CalendarSettings",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="this.$route.meta.viewName === 'map' ? 'active' : ''"
                    :to="{
                      name: 'CustomObjectMap',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-map"></i
                    >{{
                      $t(
                        "CustomObjects.MapSettings",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'treeViews' ? 'active' : ''
                    "
                    :to="{
                      name: 'CustomObjectTrees',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-tree"></i
                    >{{
                      $t(
                        "CustomObjects.TreeViewSettings",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'dependencyFields'
                        ? 'active'
                        : ''
                    "
                    :to="{
                      name: 'CustomObjectDependencyFields',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-sliders"></i
                    >{{
                      $t(
                        "CustomObjects.DependencyFields",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="co-left-nav-link"
                    :class="
                      this.$route.meta.viewName === 'relations' ? 'active' : ''
                    "
                    :to="{
                      name: 'CustomObjectRelations',
                      params: {
                        customObjectId: this.$route.params.customObjectId,
                      },
                    }"
                  >
                    <i class="bi bi-link-45deg"></i
                    >{{
                      $t(
                        "CustomObjects.Relations",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </router-link>
                </li>
              </ul>
              <router-link
                class="btn btn-warning d-block m-b-lg"
                :to="{
                  name: 'CustomObjectEdit',
                  params: { customObjectId: this.$route.params.customObjectId },
                }"
              >
                <i class="bi bi-pencil-square"></i>
                {{
                  $t(
                    "Buttons.Edit",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </router-link>
            </div>
          </div>
          <div class="col-xl-9 col-xxl-9">
            <div class="customobject-detail">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LayoutCustomObject",
  data() {
    return {
      detailTitle: "",
    };
  },
  methods: {
    getCustomObjectDetail() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomObjectGet?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.$store.commit("setCustomObject", response.data);
          this.detailTitle = response.data.name;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getDashboardWidgetCounts() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-GetCustomObjectWithChilds?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.$store.commit("setCustomObjectWithChildCountList", {
            fieldCount: response.data.fieldsCount,
            validationRuleCount: response.data.validationsCount,
            businessRuleCount: response.data.businessRulesCount,
            scriptCount: response.data.setScriptsCount,
            objectRelationCount: response.data.lookupRelationsCount,
            buttonCount: response.data.buttonsCount,
          });
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  watch: {
    "$route.params.customObjectId"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.getCustomObjectDetail();
        this.getDashboardWidgetCounts();
      }
    },
  },
  mounted() {
    this.getCustomObjectDetail();
    this.getDashboardWidgetCounts();
  },
};
</script>
